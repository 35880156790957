import Swiper, { Autoplay } from 'swiper';

export default function () {
    if (document.querySelector('.logo-slider') === null) {
        return;
    }

    const swiper = new Swiper('.logo-slider .swiper', {
        slidesPerView: 2,
        autoplay: {
            delay: 3000, 
            disableOnInteraction: false,
        },
        modules: [Autoplay],
        breakpoints: {
            768: {
                slidesPerView: 5,
            },
            480: {
                slidesPerView: 3,
            }
        }
    });
}