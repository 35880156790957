//Import component JS below here
import AOS from 'aos';
import headerInit from '../components/header/header';
// import menuInit from '../components/header-with-submenus/header-with-submenu';
// import sliderInit from '../components/slider/slider';
import tabsHomepage from "../components/tabs-homepage/tabs-homepage";
import gateSlider from "../components/gate-slider/gate-slider";
// import projectImages from "../components/project-images/project-images";
import imageSlider from "../components/image-slider/image-slider";
import headerInrijpoorten from "../components/header-inrijpoorten/header-inrijpoorten";
import configuratorLayout from "../components/configurator-layout/configurator-layout";
import contactFormSidebar from "../components/contact-form-sidebar/contact-form-sidebar";
import logoSlider from "../components/logo-slider/logo-slider";
import tabsImage from "../components/tabs-image/tabs-image";

$(document).foundation();

$(document).ready(function () {
    headerInit();
    tabsHomepage();
    gateSlider();
    imageSlider();
    headerInrijpoorten();
    configuratorLayout();
    contactFormSidebar();
    logoSlider();
    tabsImage();

    AOS.init({
        offset: 200, // offset (in px) from the original trigger point
        duration: 600, // values from 0 to 3000, with step 50ms
        easing: 'ease', // default easing for AOS animations
    });

    $('.form-group input, .form-group textarea').focusin(function () {
        let $field = $(this);
        $field.parent().parent().addClass('has-value')

    });

    $('.form-group input, .form-group textarea').focusout(function () {
        let $field = $(this);

        if ($field.val() === '' && $field.attr('type') !== 'checkbox') {
            $field.parent().parent().removeClass('has-value')
        }
    });

    $('.form-group input[type="file"]').change(function () {
        let $field = $(this);
        $field.parent().parent().addClass('has-value')
    });

    if ($('.form-group, .form-group textarea').length > 0) {
        $('.form-group input, .form-group textarea').each(function () {
            let $field = $(this);
            if ($field.val() !== '' && $field.attr('type') !== 'checkbox') {
                $field.parent().parent().addClass('has-value')
            }
        })
    }


    if ($('.form-group.icon-calendar').length > 0) {
        $('.form-group.icon-calendar input').datepicker({
            changeMonth: true,
            changeYear: true,
            dateFormat: "dd-mm-yy",
            showAnim: "slideDown",
            minDate: new Date(),
            monthNames: ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"],
            showOtherMonths: true,
            firstDay: 6,
            beforeShowDay: $.datepicker.noWeekends,
            monthNamesShort: ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"],
            dayNamesMin: ["Ma", "Di", "Wo", "Do", "Vr", "Za", "Zo"],
            onSelect: function () {
                $(this).parent().parent().addClass('has-value');
            }
        });
    }

    if ($('.scroll-btn').length > 0) {
        $('.scroll-btn').click(function () {
            let $parent = $(this).parents('section');
            const sibling = $parent.siblings('section:first');
            $([document.documentElement, document.body]).animate({
                scrollTop: sibling.offset().top
            }, 1000);
        });
    }

    if ($('.btn-scroll-to-overview').length > 0) {
        $('.btn-scroll-to-overview').on('click', function () {
            $([document.documentElement, document.body]).animate({
                scrollTop: $(".product-overview-filters ").offset().top
            }, 2000);
        });
    }

    $(document).on('click', '.header__popup-close', function() {
        $('.header__popup span').remove();
    });
});